(function () {
  'use strict';

  angular
    .module('neo.home.cancellations.canceledMatches')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.cancellations.canceledMatches', {
        url: '/canceled-matches',
        templateUrl: 'home/cancellations/canceled-matches/canceled-matches.tpl.html',
        controller: 'CanceledMatchesCtrl',
        controllerAs: 'vm',
        resolve: {
          cancellationTypes: function (CancellationTypesPublic) {
            return CancellationTypesPublic.query().$promise;
          },
          currentSeason: function (CurrentSeason) {
            return CurrentSeason.get().$promise;
          }
        }
      });
  }
}());
